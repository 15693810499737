/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: var(--clr-grey-10);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-1);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

/* section */
.section {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
  .section-center {
    width: 95vw;
  }
}

.underline {
  width: 80%;
  height: 0.25rem;
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
  background: var(--clr-grey-2);
  margin-left: auto;
  margin-right: auto;
}

/*
=============== 
Styles
===============
*/

.about::before {
  content: "";
  position: absolute;
  width: 200%;
  height: 240%;
  top: 0;
  left: 0;
  background: #d6eaec;
  background-repeat: no-repeat;
  z-index: -1;
}

.nav-logo{
  max-width: 50px;
  max-height: 50px;
}

.about-title {
  margin-top: 4rem;
  text-align: center;
}
.about-underline {
  width: 30%;
  height: 0.25rem;
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
  background: var(--clr-grey-2);
  margin-left: auto;
  margin-right: auto;
}

.about-subtitle {
  margin-top: 4rem;
  text-align: center;
  margin-bottom: 3rem;
}

.about-info {
  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  align-items: center;
  column-gap: 3rem;
  grid-template-columns: 1fr 1fr;
  display: grid;
  margin-left: auto;
  margin-right: auto;
  max-width: 60rem;
}

.about-photo {
  max-width: 25rem;
  max-height: 30rem;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: var(--radius);
  position: relative;
  border: solid var(--clr-grey-1);
}

.about-info p {
  font-size: larger;
  margin-left: 1rem;
  padding: 2rem;
}
@media screen and (max-width: 800px) {
  .about-info {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .about::before {
    width: 250%;
    height: 250%;
  }
}

.skills-p {
  padding: 20px;
  font-size: 14pt;
}
.skills-card {
  text-align: center;
  width: 40rem;
  margin-right: auto;
  margin-left: auto;
  background-color: #f1f8f9;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.portfolio-card {
  text-align: center;
  width: 60rem;
  margin-right: auto;
  margin-left: auto;
  background-color: #f1f8f9;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.video-container {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  padding: 20px;
  width: 40rem;
}

.skills-title {
  margin-top: 30px;
}

.nav {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(238, 238, 238);
  position: relative;
  z-index: 1;
}
.nav-center {
  width: 90vw;
  max-width: var(--max-width);
}
.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn {
  font-size: 1rem;
  padding: 0.25rem 0.75rem;
  border-radius: var(--radius);
  border-color: transparent;
  color: white;
  background: var(--clr-black);
  cursor: pointer;
  transition: var(--transition);
}
.btn:hover {
  background: var(--clr-grey-5);
}
.nav-links {
  display: none;
}
.nav-init {
  margin-top: 0.6rem;
  margin-left: 1rem;
}

.hero::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #d6eaec;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.hero {
  min-height: 100vh;
  margin-top: -5rem;
  display: grid;
  margin-bottom: 0.5rem;
  margin-left: 0;
}

.hero p {
  text-align: center;
  font-size: 1.3rem;
}
.hero-center {
  min-height: calc(100vh - 5rem);
  display: grid;
  place-items: center;
}

.hero-btn {
  display: grid;
  justify-content: center;
  align-items: center;
}
.home-img {
  display: none;
}
.hero h1 {
  text-align: center;
}

.hero-info{
  background-color: azure;
  padding: 15px;
  border-radius: 10px;
}
.canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

/* nav media query */
@media screen and (min-width: 800px) {
  .nav-center {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
  }
  .toggle-btn {
    display: none;
  }
  .signin-btn {
    display: inline-block;
  }
  .nav-links {
    margin-top: 1.7rem;
    display: block;
    justify-self: center;
    display: grid;
    column-gap: 3rem;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    text-align: center;
    height: 100%;
    align-items: center;
  }
  .nav-links li {
    height: 100%;
  }
  .link-btn {
    height: 100%;
    border-bottom: solid #75b8c0;
    padding: 4px;
    font-size: 1.1rem;
    color: var(--clr-grey-1);
    text-transform: capitalize;
    letter-spacing: 1px;
    width: 10rem;
  }


}

/* hero media query */
@media screen and (min-width: 800px) {
  .home-img {
    display: block;
    position: relative;
  }
  .hero-center {
    grid-template-columns: 1fr 1fr;
  }
  .home-img::before {
    content: "";
    position: absolute;
    width: 110%;
    height: 108%;
    border: 2rem solid var(--clr-grey-1);
    border-radius: 0.8rem;
    top: -5%;
    left: -5%;
  }
}

.home-photo {
  max-width: 25rem;
  max-height: 30rem;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: var(--radius);
  position: relative;
}

/* sidebar */
.sidebar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  visibility: hidden;
  z-index: -1;
  transition: var(--transition);
  transform: scale(0);
  background: rgba(0, 0, 0, 0.5);
}
.sidebar-wrapper.show {
  visibility: visible;
  z-index: 2;
  transform: scale(1);
}
.sidebar {
  width: 90vw;
  height: 95vh;
  max-width: var(--fixed-width);
  background: var(--clr-white);
  border-radius: var(--radius);
  box-shadow: var(--dark-shadow);
  position: relative;
  padding: 4rem 2rem;
}
.close-btn {
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  color: var(--clr-grey-5);
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
.sidebar article {
  margin-bottom: 2rem;
}
.sidebar-sublinks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 0.25rem;
}
.sidebar-sublinks a {
  display: block;
  color: var(--clr-grey-1);
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
.sidebar-sublinks svg {
  color: var(--clr-grey-5);
  margin-right: 1rem;
}

@media screen and (min-width: 800px) {
  .sidebar-wrapper {
    display: none;
  }
}

/* links */
.submenu {
  background: var(--clr-white);
  box-shadow: var(--dark-shadow);
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  display: none;
  padding: 2rem;
  border-radius: var(--radius);
  transition: var(--transition);
}
.submenu::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--clr-white);
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.submenu.show {
  display: block;
}

.submenu-center {
  display: grid;
  gap: 0.25rem 2rem;
}
.col-2 {
  grid-template-columns: repeat(2, 1fr);
}
.col-3 {
  grid-template-columns: repeat(3, 1fr);
}
.col-4 {
  grid-template-columns: repeat(4, 1fr);
}
.submenu h4 {
  margin-bottom: 1.5rem;
}
.submenu-center a {
  width: 10rem;
  display: block;
  color: var(--clr-grey-1);
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
.submenu-center svg {
  color: var(--clr-grey-5);
  margin-right: 1rem;
}
